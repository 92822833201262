<div class="bounds" fxLayout="column" fxFlexFill>
  <div>
    <mat-toolbar color="primary" *ngIf="showMenu$ | async">
      <mat-toolbar-row>
        <div class="logo">
          <a mat-button [routerLink]="'/'" routerLinkActive="active" (click)="redirectToPage()">
            <img src="/assets/images/logo.png" alt="Logo" />
          </a>
        </div>
        <span class="menu-spacer"></span>
        <div class="menu-wrapper">
          <div class="menu-wrapper-1" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayoutAlign="start center">
              <a mat-button [routerLink]="'/freezer'" routerLinkActive="active" (click)="redirectToPage()"> Freezers
              </a>
              <a mat-button [routerLink]="'/sample'" routerLinkActive="active" (click)="redirectToPage()"> Samples </a>
              <a mat-button [routerLink]="'/aliquot/aliquots'" routerLinkActive="active" (click)="redirectToPage()">
                Aliquots </a>
              <a mat-button [routerLink]="'/patient'" *ngIf="showPatientsMenu$ | async" routerLinkActive="active"
                (click)="redirectToPage()"> Patients </a>
              <a mat-button [routerLink]="'/report'" routerLinkActive="active" (click)="redirectToPage()"> Reports </a>
            </div>
            <div fxLayoutAlign="end center">
              <a *ngIf="showAdminMenu$ | async" mat-button (click)="redirectToPage('admin')"
                [ngClass]="selectedAdminMenu?'active':''"> Administration </a>
              <div>
                <button mat-button [matMenuTriggerFor]="departmentsAndRolesMenu">
                  {{selectedDepartmentsAndRoles?.department?.name}}
                </button>
                <mat-menu #departmentsAndRolesMenu="matMenu">
                  <button mat-menu-item *ngFor="let departmentsAndRole of departmentsAndRoles"
                    (click)="changedDepartmentsAndRole(departmentsAndRole)">{{departmentsAndRole?.department?.name}}</button>
                </mat-menu>
              </div>
              <div class="secondary-menu-wrapper">
                <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" *ngIf="user">
                  {{user?.firstName?.charAt(0)}}{{user?.lastName?.charAt(0)}}
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="logout()">Log out</button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
  <div>
    <div class="" fxLayout="row" fxLayout.xs="column">
      <div class="center" fxFlex="100" *ngIf="showSpinner$ | async">
        <mat-progress-spinner mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>
    <div class="" fxLayout="row" fxLayout.xs="column">
      <div fxFlex="15" fxFlex.xs="55"></div>
      <div fxFlex="70" class="responsive-ui">
        <router-outlet></router-outlet>
      </div>
      <div fxFlex="15" fxFlex.xs="15"></div>
    </div>
  </div>

</div>