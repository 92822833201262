import { StickyDirection } from "@angular/cdk/table";

export class dataTableEventArgs{
    value: string;
    data: any;
  }

  export class GenericResponse
{
    isError: boolean;
    message: string;
    data: object;
}

export class SuggestionsList{
  suggestions: string[];
}

export class FilterList{
  column: string;
  condition: any[];
  type: string;
  dbColumnName: string;
  defaultCondition: string;
  values: any[];
  value: string;
  fromValue: string;
  toValue: string;
  tblAlias: string;
  property: string;
}

export class OrderByInput {
  property: string;
  orderBy: OrderByType;
}

export enum OrderByType {
  Asc = 'Asc',
  Desc = 'Desc'
}

export class Attachment
{
  id: string;
  createdBy: string;
  createdOn: string;
  name: string;
  description: string;
  recordType: string;
  s3SignedUrl: string
}

export class AttachmentPagedList
{
    count: number;
    attachments: Attachment[];
}

export class UploadAttachmentInput
{
  file: any;
  description: string;
}