import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.less']
})
export class UnauthorisedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
