import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
// import { AuthenticationService } from '../../services/authentication.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../services/auth-config';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from 'src/app/user/user.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ApiService } from 'src/app/shared/service/api.service';
import { User } from 'src/app/user/user';
import { SpinnerService } from 'src/app/shared/spinner.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit, OnDestroy {
  public userProfileLoadedEvent: EventEmitter<Object>;

  constructor(private oauthService: OAuthService, 
    private authenticationService: AuthenticationService,
    private userService: UserService, 
    private commonService: CommonService, 
    private apiService: ApiService, 
    private router: Router,
    private spinnerService: SpinnerService) {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin({ customHashFragment: location.hash }).then(r => {
      if (!this.oauthService.hasValidAccessToken()) {
        console.log("Init code Flow....");
        this.oauthService.initCodeFlow();
        return;
      }
      else {
        this.spinnerService.show();
        let claims = this.authenticationService.loggedinUserClaims;
        this.setUserSession();
        this.apiService.refreshApiEndpoints();
        if (claims["custom:system_admin"] === 'false') {
          let user: any = [];
          this.commonService.showMenu$.next(true);
          const departmentAndRoleList: any = claims['custom:user_owners'].split(',');
          const departmentAndRoleValues: any = departmentAndRoleList[0].split(':');
          
          if(departmentAndRoleValues[2] === "PHIUser" || departmentAndRoleValues[2]==='Admin')
          {
            if(departmentAndRoleValues[2]==='Admin'){
              this.commonService.showAdminMenu$.next(true);
              this.commonService.isAdmin$.next(false);
            }
            else{
              this.commonService.showAdminMenu$.next(false);
            }
            //Admin is also PHIUser by default.Hence show patient tab to both.
            this.commonService.showPatientsMenu$.next(true);
          }
          else{
            this.commonService.showPatientsMenu$.next(false);
            this.commonService.showAdminMenu$.next(false);
          }
          this.userService.user$.next(user);
        }
        else {
          this.userService.getUserById(claims["sub"]).subscribe(user => {
            if (user) {
              this.userService.addUserCache(user.id);
            }
            this.userService.user$.next(user);
            this.commonService.showMenu$.next(true);
            this.commonService.showAdminMenu$.next(true);
            this.commonService.showPatientsMenu$.next(true);
          });
        }
      }
    });
  }

  setUserSession() {
    let claims = this.authenticationService.loggedinUserClaims;
    sessionStorage.setItem('tenant_id', claims["custom:tenant_id"]);
    this.setUserOwner();
    sessionStorage.setItem('is_sys_admin', claims["custom:system_admin"]);
  }

  setUserOwner() {
    let claims = this.authenticationService.loggedinUserClaims;
    let userOwnerClaim = claims["custom:user_owners"];
    //e.g.: "custom:user_owners": "Pathalogy:e74eeb3c-cc52-4bf0-b12b-3a019a04ecb2:User,Microbiology:771ee038-b7d0-42e5-b5ac-c47d4eed3cc4:PHIUser"
    if (userOwnerClaim) {
      let defaultDepartmentAndRole = userOwnerClaim.split(',')[0];
      let departmentDetails = defaultDepartmentAndRole.split(':');
      sessionStorage.setItem('owner_id', departmentDetails[1]);
      sessionStorage.setItem('role', departmentDetails[2]);
    }
  }

  ngOnDestroy(): void {
    this.commonService.showMenu$.next(true);
  }
  ngOnInit(): void {
    this.commonService.showMenu$.next(false);
  }
}
