import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';

const apiURL = environment.apiUrl;

export function createDefaultApollo(httpLink?: HttpLink): ApolloClientOptions<any> {
  let tenantId = sessionStorage.getItem('tenant_id');
  const tenantApiURL = `${apiURL}/${tenantId}`
  const user_uri = `${tenantApiURL}/administration`;
  return {
    link: httpLink.create({ uri: user_uri }),
    cache: new InMemoryCache(),
  };
}

export function createNamedApollo(httpLink?: HttpLink): Record<string, ApolloClientOptions<any>> {
  let ownerId = sessionStorage.getItem('owner_id');
  let tenantId = sessionStorage.getItem('tenant_id');
  const tenantApiURL = `${apiURL}/${tenantId}`
  const ownerApiURL = `${tenantApiURL}/owners/`

  const sample_uri = ownerApiURL + ownerId + '/samples';
  const patient_uri = ownerApiURL + ownerId + '/patients';
  const report_uri = ownerApiURL + ownerId + '/reports';
  const inventory_uri = ownerApiURL + ownerId + '/inventory';

  const defaultOptions: DefaultOptions = {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all"
    },
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore"
    }
  }

  return {
    sample: {
      link: httpLink.create({ uri: sample_uri }),
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions
    },
    patient: {
      link: httpLink.create({ uri: patient_uri }),
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions
    },
    inventory: {
      link: httpLink.create({ uri: inventory_uri }),
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions
    },
    report: {
      link: httpLink.create({ uri: report_uri }),
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions
    }

  };
}



@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createDefaultApollo,
      deps: [HttpLink],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [HttpLink],
      useFactory: createNamedApollo
    }
  ],
})
export class GraphQLModule {
}
