import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.identityProviderConfig.issuer_uri,
  redirectUri: environment.identityProviderConfig.redirect_uri,
  clientId: environment.identityProviderConfig.client_id,
  scope: 'openid profile email',
  strictDiscoveryDocumentValidation: false,
  // Activate Session Checks:
  sessionChecksEnabled: false,
  responseType: 'code',
  timeoutFactor: 0.75, //testing purposes only, refresh every 8 seconds, in real dev leave it default 75% of token time
  showDebugInformation: true,
  requireHttps: false,
  logoutUrl: environment.identityProviderConfig.logout_endpoint
}