import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { authConfig } from './auth-config';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  constructor(private oauthService: OAuthService) {
  }

  get loggedinUserClaims(): object {
    return this.oauthService.getIdentityClaims();
  }

  isUserLoggedin(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  logoutUser() {
    this.oauthService.configure(authConfig);
    this.oauthService.logOut({
      "client_id": environment.identityProviderConfig.client_id,
      "logout_uri": environment.identityProviderConfig.logout_redirect_uri
    });
  }
}
