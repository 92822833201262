export const environment = {
  production: true,
  identityProviderConfig:{
    issuer_uri:"https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_qfZC3eQEz",
    redirect_uri: window.location.origin+'/login',
    logout_redirect_uri: window.location.origin,
    client_id: "25ohecsbs1gj0dnp149cmdg1jk",
    silent_redirect_uri: window.location.origin,
    logout_endpoint: "https://onq-rpm-uat.auth.ap-southeast-2.amazoncognito.com/logout"
  },
  apiUrl: "https://api.biobanq.app"
};
