export class User{
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    pictureUri: string;
    status: string;
    role: string;
    username: string;
    enabled: boolean;
    tenantId: string;
}

export class UserTableModel {
    tableData: any;
    pageMode: string;
}

export class UpdateUserInput{
    id: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    pictureUri: string;
}

export class CreateUserInput{
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    pictureUri: string;
}