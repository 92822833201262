import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/component/auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/services/auth.guard.service';
import { SharedModule } from './shared/shared.module';
import { UnauthorisedComponent } from './auth/unauthorised/unauthorised.component';
import { AliquotComponent } from './sample/aliquot/aliquot.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'freezer', loadChildren: () => import('./freezer/freezer.module').then(module => module.FreezerModule), canActivate: [AuthGuard] },
  { path: 'sample', loadChildren: () => import('./sample/sample.module').then(module => module.SampleModule), canActivate: [AuthGuard] },
  { path: 'aliquot', loadChildren: () => import('./sample/sample.module').then(module => module.SampleModule), canActivate: [AuthGuard] },
  { path: 'patient', loadChildren: () => import('./patient/patient.module').then(module => module.PatientModule), canActivate: [AuthGuard] },
  { path: 'report', loadChildren: () => import('./report/report.module').then(module => module.ReportModule) },
  { path: 'admin/user', loadChildren: () => import('./user/user.module').then(module => module.UserModule), canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(module => module.AdminModule), canActivate: [AuthGuard] },
  { path: 'admin/department', loadChildren: () => import('./department/department.module').then(module => module.DepartmentModule), canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '401', component: UnauthorisedComponent },
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
