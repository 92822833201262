import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { delay, startWith, tap } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(private spinnerService: SpinnerService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authorizationtoken = sessionStorage.getItem('access_token') ?? '';
        const modifiedReq = req.clone({
            headers: req.headers.set('authorizationtoken', authorizationtoken),
        });
        //dont show the spinner when silent token refresh is triggered.
        if (!req.url.includes('oauth2/token'))
            this.spinnerService.show();
        return next
            .handle(modifiedReq)
            .pipe(
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse && !event.url.includes('cognito')) {
                        setTimeout(()=>{
                            this.spinnerService.hide();
                        },1000);
                    }
                }, (error) => {
                    this.spinnerService.hide();
                })
            );
    }
}