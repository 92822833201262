import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpLink } from 'apollo-angular/http';
import { Subject } from 'rxjs';
import { SpinnerService } from '../app/shared/spinner.service';
import { AuthenticationService } from './auth/services/authentication.service';
import { Department } from './department/model/department';
import { DepartmentsAndRole } from './department/model/departmentsAndRoles';
import { CommonService } from './shared/service/common.service';
import { User } from './user/user';
import { UserService } from './user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'repository-manager';
  showSpinner$ = new Subject<boolean>();
  showMenu$ = new Subject<boolean>();
  user: User;
  departmentsAndRoles: DepartmentsAndRole[] = [];
  selectedDepartmentsAndRoles: DepartmentsAndRole;
  userRole: string = 'Admin';
  showAdminMenu$ = new Subject<boolean>();
  showPatientsMenu$ = new Subject<boolean>();
  selectedAdminMenu: boolean = false;
  constructor(private spinnerService: SpinnerService, private authService: AuthenticationService,
    private userService: UserService, private commonService: CommonService,
    private router: Router, private httpLink: HttpLink, private oauthService: OAuthService,) {
    this.showSpinner$ = spinnerService.visibility;
    this.showMenu$ = commonService.showMenu$;
    this.showAdminMenu$ = commonService.showAdminMenu$;
    this.showPatientsMenu$ = commonService.showPatientsMenu$;

    this.userService.user$.subscribe(data => {
      if (data) {
        this.userRole = sessionStorage.getItem('is_sys_admin');
        if (this.userRole === 'false') {
          this.getUserRoleUser();
        }
        else {
          this.userService.getUserById(this.userService.getLoggedinUserId()).subscribe((user: any) => {
            this.user = user;
          });
        }
        this.getDepartmentsAndRoles();
        this.selectedDepartmentsAndRoles = this.departmentsAndRoles[0];
        this.router.navigate(['/freezer']);
      }
      else {
        this.commonService.showMenu$.next(false);
        this.router.navigate(['/401']);
        return;
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf('/admin') > -1) {
          this.selectedAdminMenu = true;
        }
        else {
          this.selectedAdminMenu = false;
        }
        if (event.url === '/401') {
          this.commonService.showMenu$.next(false);
        }
      }
    });
    if (!this.user) {
      if (sessionStorage.getItem('role')) {
        this.userRole = sessionStorage.getItem('role');
      }
      this.getUserById();
    }
  }

  refresh(): void {
    window.location.reload();
  }

  logout() {
    this.userService.removeUserCache();
    this.authService.logoutUser();
    localStorage.clear();
    sessionStorage.clear();
    this.userService.signoutUser(this.user.id).subscribe(data => {
      this.router.navigate(['/login']);
    });
  }

  changedDepartmentsAndRole(departmentsAndRoles: any): void {
    this.selectedDepartmentsAndRoles = departmentsAndRoles;
    this.userRole = departmentsAndRoles.role;
    sessionStorage.setItem('owner_id', departmentsAndRoles.department.ownerId);
    sessionStorage.setItem('role', departmentsAndRoles.role);
    this.router.navigate(['/freezer']).then(() => {
      this.refresh();
    });
  }

  getUserById(): void {
    this.userRole = sessionStorage.getItem('is_sys_admin');
    this.getDepartmentsAndRoles();
    let ownerId: string = sessionStorage.getItem('owner_id');
    this.selectedDepartmentsAndRoles = this.departmentsAndRoles.find(x => x.department.ownerId === ownerId);
    if (this.userRole === 'false') {
      this.getUserRoleUser();
      if (this.selectedDepartmentsAndRoles.role === 'Admin' || this.selectedDepartmentsAndRoles.role === 'PHIUser') {
        if (this.selectedDepartmentsAndRoles.role === 'Admin') {
          this.commonService.showAdminMenu$.next(true);
          this.commonService.isAdmin$.next(false);
        }
        else {
          this.commonService.showAdminMenu$.next(false);
        }
        this.commonService.showPatientsMenu$.next(true);
      }
      else {
        this.commonService.showPatientsMenu$.next(false);
        this.commonService.showAdminMenu$.next(false);
      }
    }
    else if (this.userRole === 'true') {
      this.userService.getUserById(this.userService.getLoggedinUserId()).subscribe((user: any) => {
        this.user = user;
        this.commonService.showMenu$.next(true);
        this.commonService.showAdminMenu$.next(true);
        this.commonService.showPatientsMenu$.next(true);
      });
    }
  }

  getUserRoleUser(): void {
    let claims = this.authService.loggedinUserClaims;
    const user: User = new User();
    user.firstName = claims['given_name'];
    user.lastName = claims['family_name'];
    this.user = user;
  }

  getDepartmentsAndRoles(): void {
    let claims = this.authService.loggedinUserClaims;
    if (claims) {
      const departmentAndRoleList: any = claims['custom:user_owners'].split(',');
      if (departmentAndRoleList) {
        this.departmentsAndRoles = [];
        departmentAndRoleList.forEach((value: any) => {
          const departmentAndRole: DepartmentsAndRole = new DepartmentsAndRole();
          departmentAndRole.department = new Department();
          const departmentAndRoleValues: any = value.split(':');
          departmentAndRole.department.name = departmentAndRoleValues[0];
          departmentAndRole.department.ownerId = departmentAndRoleValues[1];
          departmentAndRole.role = departmentAndRoleValues[2];
          (this.departmentsAndRoles.indexOf(departmentAndRole) === -1) ? this.departmentsAndRoles.push(departmentAndRole) : '';
        });
      }
    }
  }

  redirectToPage(routerLink?: string): void {
    if (routerLink) {
      this.selectedAdminMenu = true;
      this.router.navigate(["/", routerLink]);
    }
    else {
      this.selectedAdminMenu = false;
    }
  }
}
