import { Injectable, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { shareReplay, map, catchError } from 'rxjs/operators';
import { ApolloError } from '@apollo/client/core';
import { Writable } from 'stream';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { CreateUserInput, UpdateUserInput, User } from './user';
import { CommonService } from '../shared/service/common.service';
import { GenericResponse } from '../shared/shared.modal';




@Injectable({
  providedIn: 'root'
})
export class UserService {
  user$: Subject<User> = new Subject<User>();
  constructor(private apollo: Apollo, public snackBar: MatSnackBar, private commonService: CommonService) { }

  addUserCache(id: string)
  {
    sessionStorage.setItem('rm_user',id);
  }

  getLoggedinUserId()
  {
    return sessionStorage.getItem('rm_user');
  }

  removeUserCache()
  {
    sessionStorage.removeItem('rm_user');
  }
  
  isPHIUser() : boolean
  {
    let role = sessionStorage.getItem('role');

    return role && role  !== 'User';
  }

  getUsers(skip: number, take: number, searchQry?: string): Observable<User[]> {
    return this.apollo
      .watchQuery<User>(
        {
          query: USERS,
          variables: { skip: skip, take: take, searchQry: searchQry },
          fetchPolicy: 'network-only'
        })
      .valueChanges.pipe(
        map((result: any) => {
          return result?.data?.Users;
        })
      );
  }

  getUserById(sub: string) : Observable<User> {
    if(sub){
      const view = gql`
      query User($id: String!) {
        User(id: $id) {
          id
          firstName
          lastName
          email
          username
          mobileNumber
          pictureUri
          status
          departmentsAndRoles
          {
            id
            department
            {
              id
              name
              ownerId
              deleted
            }
            role
          }
          enabled
          tenantId
        }
      }
    `;
      return this.apollo
        .watchQuery<User>(
          {
            query: view,
            variables: { id: sub },
            fetchPolicy: 'network-only'
          })
        .valueChanges.pipe(
          map((result: any) => {
            return result?.data?.User;
          }),
          catchError(error=>{
            return of(new Error("Sorry, something went wrong while processing your request"));
          })
        );
    }
    
  }

  updateUser(user: UpdateUserInput): Observable<any>{
    const UPDATE_USER_MUTATION = gql`
    mutation UpdateUser($user: UpdateUserInput!) {
      UpdateUser(input: $user) {
        id
        username
        firstName
        lastName
        email
        mobileNumber
        status
        departmentsAndRoles
        {
          department
          {
            name
          }
          role
        }
        enabled
        tenantId
      }
    }
    `;
    return this.apollo.mutate({
      mutation: UPDATE_USER_MUTATION,
      variables: {
        user
      },
      update: (store, mutationResult: any) => {

      }
    }).pipe(map((data) => {
      return this.commonService.buildResponse("User updated successfully.");
    }), catchError(error => {
      return of(this.commonService.buildResponse(error.message ? error.message : '', true));
    }));
  }

  resetPassword(id: String): Observable<any>{
    const RESET_USER_PASSWORD_MUTATION = gql`
    mutation ResetUserPassword($id: String!) {
      ResetUserPassword(id: $id) {
        id
        username
        firstName
        lastName
      }
    }
    `;
    return this.apollo.mutate({
      mutation: RESET_USER_PASSWORD_MUTATION,
      variables: {
        id
      },
      update: (store, mutationResult: any) => {
      }
    }).pipe(map((data) => {
      return this.commonService.buildResponse("User password reset successfully.");
    }), catchError(error => {
      return of(this.commonService.buildResponse(error.message ? error.message : '', true));
    }));
  }

  disableUser(id: String): Observable<any>{
    const DISABLE_USER_MUTATION = gql`
    mutation DisableUser($id: String!) {
      DisableUser(id: $id) {
        id
        username
        firstName
        lastName
      }
    }
    `;
    return this.apollo.mutate({
      mutation: DISABLE_USER_MUTATION,
      variables: {
        id
      },
      update: (store, mutationResult: any) => {
      }
    }).pipe(map((data) => {
      return this.commonService.buildResponse("User disabled successfully.");
    }), catchError(error => {
      return of(this.commonService.buildResponse(error.message ? error.message : '', true));
    }));
  }

  enableUser(id: String): Observable<any>{
    const ENABLE_USER_MUTATION = gql`
    mutation EnableUser($id: String!) {
      EnableUser(id: $id) {
        id
        username
        firstName
        lastName
      }
    }
    `;
    return this.apollo.mutate({
      mutation: ENABLE_USER_MUTATION,
      variables: {
        id
      },
      update: (store, mutationResult: any) => {
      }
    }).pipe(map((data) => {
      return this.commonService.buildResponse("User enabled successfully.");
    }), catchError(error => {
      return of(this.commonService.buildResponse(error.message ? error.message : '', true));
    }));
  }

  signoutUser(id: String): Observable<any>{
    const RESET_USER_PASSWORD_MUTATION = gql`
    mutation SignoutUser($id: String!) {
      SignoutUser(id: $id) {
        id
        username
        firstName
        lastName
      }
    }
    `;
    return this.apollo.mutate({
      mutation: RESET_USER_PASSWORD_MUTATION,
      variables: {
        id
      },
      update: (store, mutationResult: any) => {
      }
    }).pipe(map((data) => {
      return this.commonService.buildResponse("User Signed out successfully.");
    }), catchError(error => {
      return of(this.commonService.buildResponse(error.message ? error.message : '', true));
    }));
  }

  addUser(user: CreateUserInput): Observable<any>{
    const CREATE_PATIENT_MUTATION = gql`
    mutation CreateUser($user: CreateUserInput!) {
      CreateUser(input: $user) {
        id
        firstName
        lastName
      }
    }
    `;
    return this.apollo.mutate({
      mutation: CREATE_PATIENT_MUTATION,
      variables: {
        user
      },
      update: (store, mutationResult: any) => {

      }
    }).pipe(map((data) => {
      return this.commonService.buildResponse("User added successfully.");
    }), catchError(error => {
      return of(this.buildErrorResponse(error));
    }));
  }

  private buildErrorResponse(error: any): GenericResponse {
    let errResponse: GenericResponse = new GenericResponse();
    errResponse.isError = true;
    if (error.graphQLErrors) {
      errResponse.message = error.message;
    }
    else if (error.networkError) {
      errResponse.message = "Sorry, something went wrong while processing your request.";
    }

    return errResponse;
  }
}

const USERS = gql`
{
  Users
  {
    id,
    username,
    email,
    firstName,
    lastName,
    mobileNumber,
    status,
    enabled
  }
}
`;
