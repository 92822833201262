import { Injectable } from '@angular/core';
import { HttpLink } from 'apollo-angular/http';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';

const apiURL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private apollo: Apollo, private httpLink: HttpLink)
  {

  }
  refreshApiEndpoints()
  {
    let ownerId = sessionStorage.getItem('owner_id');
    let tenantId = sessionStorage.getItem('tenant_id');
    const tenantApiURL = `${apiURL}/${tenantId}`
    const ownerApiURL = `${tenantApiURL}/owners/`
    const patient_uri = ownerApiURL + ownerId + '/patients';
    const sample_uri = ownerApiURL + ownerId + '/samples';
    const report_uri = ownerApiURL + ownerId + '/reports';
    const inventory_uri = ownerApiURL + ownerId + '/inventory';
    const user_uri = `${tenantApiURL}/administration`;
    const label_uri = `${tenantApiURL}/labels`;

    this.apollo.client.setLink(this.httpLink.create({uri: user_uri}));
    this.apollo.use('patient').client.setLink(this.httpLink.create({uri: patient_uri}));
    this.apollo.use('sample').client.setLink(this.httpLink.create({uri: sample_uri}));
    this.apollo.use('inventory').client.setLink(this.httpLink.create({uri: inventory_uri}));
    this.apollo.use('report').client.setLink(this.httpLink.create({uri: report_uri}));
    // this.apollo.use('label').client.setLink(this.httpLink.create({uri: label_uri}));
  }
}
