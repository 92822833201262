export class DepartmentPagedList {
    count: number;
    departments: Department[];
}

export class Department {
    id: string;
    ownerId: string;
    createdBy: string;
    createdOn: Date;
    modifiedBy: string;
    modifiedOn: Date;
    deleted: boolean;
    name: string;
    description: string;
};

export class CreateDepartmentInput{
    name: string;
    description: string;
}

export class UpdateDepartmentInput{
    id: string;
    name: string;
    description: string;
}