import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { GenericResponse } from '../shared.modal';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public tableData = new BehaviorSubject([]);
  public kendotabledata = new BehaviorSubject([]);
  public suggestionData = new BehaviorSubject([]);
  public showMenu$: BehaviorSubject<boolean>;
  public showAdminMenu$: BehaviorSubject<boolean>;
  public showPatientsMenu$: BehaviorSubject<boolean>;
  public isAdmin$: BehaviorSubject<boolean>;
  public suggestionSearchText = new Subject<string>();

  constructor(public snackBar: MatSnackBar, private http: HttpClient) {
    this.showMenu$ = new BehaviorSubject(true);
    this.showAdminMenu$ = new BehaviorSubject(true);
    this.showPatientsMenu$ = new BehaviorSubject(true);
    this.isAdmin$ = new BehaviorSubject(true);
  }

  refreshTableData(item): void {
    this.tableData.next(item);
  }

  refreshKendoTabledata(item): void {
    this.kendotabledata.next(item);
  }

  refreshSuggestionData(item): void {
    this.suggestionData.next(item);
  }

  openSnackBar(message: string, isError: boolean = false): void {
    this.snackBar.open(message, 'X', {
      duration: 12000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: isError ? ['mat-toolbar', 'mat-warn'] : ['mat-toolbar', 'green-snackbar']
    });
  }

  buildResponse(message: string, isError: boolean = false): GenericResponse {
    let response: GenericResponse = new GenericResponse();
    response.isError = isError;
    response.message = isError ? "Sorry, something went wrong while processing your request." : message; response.message = message ? message : (isError ? "Sorry, something went wrong while processing your request." : "Request processed successfully");
    return response;
  }

  handleResponse(response: any, successCallback: Function = null, errorCallback: Function = null) {
    let data: GenericResponse = response as GenericResponse;
    this.openSnackBar(data.message, data.isError);
    if (!data.isError && successCallback) {
      successCallback();
    }
    else if (data.isError && errorCallback) {
      errorCallback();
    }
  }

  uploadFile(fileuploadurl, file, type): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': type });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
      });
    return this.http.request(req);
  }

}
